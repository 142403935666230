<template>
    <div>
        <div class="d-flex align-center">
            <div class="d-flex flex-column ml-4 align-self-start">
                <div class="mb-2">
                    <h4 class="mb-2">
                        <v-icon class="mr-2 primary--text text--disabled"
                            >mdi-home-city</v-icon
                        >
                        <span
                            class="primary--text text--lighten-1 text-uppercase font-weight-regular"
                            >Applied To:</span
                        >
                        <span class="font-weight-bold text-uppercase">
                            {{ property.name }}</span
                        >
                    </h4>
                    <h4 class="mb-2">
                        <v-icon class="mr-2 primary--text text--disabled"
                            >mdi-door-closed</v-icon
                        >
                        <span
                            class="primary--text text--lighten-1 text-uppercase font-weight-regular"
                            >Unit:</span
                        >
                        <span class="font-weight-bold text-uppercase">
                            {{ applicant.application.unit }}</span
                        >
                    </h4>
                    <h4 class="mb-2">
                        <v-icon class="mr-2">mdi-calendar-cursor</v-icon>
                        <span
                            class="primary--text text--lighten-1 text-uppercase font-weight-regular"
                            >Date Submitted:</span
                        >
                        <span class="font-weight-bold">
                            {{ created_at.split(" ").shift() }}</span
                        >
                    </h4>
                    <h4 class="mb-2">
                        <v-icon class="mr-2">mdi-calendar-arrow-right</v-icon>
                        <span
                            class="primary--text text-uppercase font-weight-regular"
                            >Desired Move-In:</span
                        >
                        <span class="font-weight-bold"> 04/22/2021</span>
                    </h4>
                </div>
            </div>
        </div>
        <div class="d-flex align-center">
            <div class="my-2 ml-2">
                <div class="d-flex">
                    <v-icon small color="secondary" class="mr-2"
                        >mdi-email</v-icon
                    >
                    {{ applicant.email }}
                </div>
                <div class="d-flex">
                    <v-icon small color="secondary" class="mr-2"
                        >mdi-phone</v-icon
                    >
                    {{ applicant.phone }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "applicant-profile-details",
    components: {},
    props: {
        applicant: {
            type: Object,
            required: true,
        },
        created_at: {
            type: String,
            required: true,
        },
        property: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>