var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex align-center" }, [
      _c("div", { staticClass: "d-flex flex-column ml-4 align-self-start" }, [
        _c("div", { staticClass: "mb-2" }, [
          _c(
            "h4",
            { staticClass: "mb-2" },
            [
              _c(
                "v-icon",
                { staticClass: "mr-2 primary--text text--disabled" },
                [_vm._v("mdi-home-city")]
              ),
              _c(
                "span",
                {
                  staticClass:
                    "primary--text text--lighten-1 text-uppercase font-weight-regular"
                },
                [_vm._v("Applied To:")]
              ),
              _c("span", { staticClass: "font-weight-bold text-uppercase" }, [
                _vm._v(" " + _vm._s(_vm.property.name))
              ])
            ],
            1
          ),
          _c(
            "h4",
            { staticClass: "mb-2" },
            [
              _c(
                "v-icon",
                { staticClass: "mr-2 primary--text text--disabled" },
                [_vm._v("mdi-door-closed")]
              ),
              _c(
                "span",
                {
                  staticClass:
                    "primary--text text--lighten-1 text-uppercase font-weight-regular"
                },
                [_vm._v("Unit:")]
              ),
              _c("span", { staticClass: "font-weight-bold text-uppercase" }, [
                _vm._v(" " + _vm._s(_vm.applicant.application.unit))
              ])
            ],
            1
          ),
          _c(
            "h4",
            { staticClass: "mb-2" },
            [
              _c("v-icon", { staticClass: "mr-2" }, [
                _vm._v("mdi-calendar-cursor")
              ]),
              _c(
                "span",
                {
                  staticClass:
                    "primary--text text--lighten-1 text-uppercase font-weight-regular"
                },
                [_vm._v("Date Submitted:")]
              ),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(" " + _vm._s(_vm.created_at.split(" ").shift()))
              ])
            ],
            1
          ),
          _c(
            "h4",
            { staticClass: "mb-2" },
            [
              _c("v-icon", { staticClass: "mr-2" }, [
                _vm._v("mdi-calendar-arrow-right")
              ]),
              _c(
                "span",
                {
                  staticClass:
                    "primary--text text-uppercase font-weight-regular"
                },
                [_vm._v("Desired Move-In:")]
              ),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(" 04/22/2021")
              ])
            ],
            1
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "d-flex align-center" }, [
      _c("div", { staticClass: "my-2 ml-2" }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "v-icon",
              { staticClass: "mr-2", attrs: { small: "", color: "secondary" } },
              [_vm._v("mdi-email")]
            ),
            _vm._v(" " + _vm._s(_vm.applicant.email) + " ")
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "v-icon",
              { staticClass: "mr-2", attrs: { small: "", color: "secondary" } },
              [_vm._v("mdi-phone")]
            ),
            _vm._v(" " + _vm._s(_vm.applicant.phone) + " ")
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }